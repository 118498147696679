import React from "react";

interface Props extends React.HTMLAttributes<SVGSVGElement> {}

export function DeleteIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                // eslint-disable-next-line max-len
                d="M4.4 14L3.65372 14.0746C3.69206 14.458 4.01469 14.75 4.4 14.75L4.4 14ZM11.6 14L11.6 14.75C11.9853 14.75 12.3079 14.458 12.3463 14.0746L11.6 14ZM2.75372 5.07463L3.65372 14.0746L5.14628 13.9254L4.24628 4.92537L2.75372 5.07463ZM4.4 14.75L11.6 14.75L11.6 13.25L4.4 13.25L4.4 14.75ZM12.3463 14.0746L13.2463 5.07463L11.7537 4.92537L10.8537 13.9254L12.3463 14.0746Z"
                fill="currentColor"
            />
            <path d="M2 5H14" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
            <path
                d="M5 4V2.94297C5 2.42218 5.42218 2 5.94297 2H10.057C10.5778 2 11 2.42218 11 2.94297V4"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="square"
            />
        </svg>
    );
}
DeleteIcon.displayName = "DeleteIcon";
