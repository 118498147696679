import React, { useState } from "react";
import { useTable, Column as ReactTableColumn } from "react-table";
import classNames from "classnames";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Typography } from "@vp/swan";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { DeleteIcon, PlusIcon } from "@shared/features/StudioChrome";
import { TableContainerCell } from "./TableContainerCell";
import { ReactTableRowData } from "./types";

import * as styles from "./TableContainer.module.scss";

const messages = defineMessages({
    bigAddRowButton: {
        id: "easel.components.tables.tableDialog.bigAddRowButton",
        defaultMessage: "Add row to end of table",
        description: {
            note: "Aria Label for Big Add Row Button"
        }
    },
    bigAddRowButtonText: {
        id: "easel.components.tables.tableDialog.bigAddRowButtonText",
        defaultMessage: "Add Row",
        description: {
            note: "Text for the Big Add Row Button"
        }
    },
    addRowButton: {
        id: "easel.components.tables.tableRow.addRowButton",
        defaultMessage: "Add Row Button",
        description: {
            note: "Aria label for Add Row Button"
        }
    },
    deleteRowButton: {
        id: "easel.components.tables.tableRow.deleteRowButton",
        defaultMessage: "Delete Row Button",
        description: {
            note: "Aria label for Delete Row Button"
        }
    }
});

const defaultColumn: Partial<ReactTableColumn<ReactTableRowData>> = {
    Cell: TableContainerCell
};

export interface TableContainerProps {
    reactTableData: ReactTableRowData[];
    reactTableColumns: ReactTableColumn<ReactTableRowData>[];
    updateTableItemData: (rowIndex: number, columnId: string | undefined, cellText: string) => void;
    onAddRow: (index: number) => void;
    onDeleteRow: (index: number) => void;
}

export function TableContainer({
    reactTableData: data,
    reactTableColumns: columns,
    updateTableItemData,
    onAddRow,
    onDeleteRow
}: TableContainerProps) {
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const [focusRowIndex, setFocusRowIndex] = useState(-1);

    const { getTableBodyProps, rows, prepareRow, getTableProps } = useTable({
        columns,
        data,
        defaultColumn
    });

    return (
        <div className={classNames("easel-table-wrapper", styles.tableWrapper)}>
            <table className={classNames("easel-table-container", styles.tableContainer)} {...getTableProps()}>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, rowIndex) => {
                        prepareRow(row);

                        return (
                            <tr
                                className={classNames(
                                    styles.tableRow,
                                    { focused: rowIndex === focusRowIndex },
                                    { [styles.tableRowFocused]: rowIndex === focusRowIndex }
                                )}
                                key={rowIndex}
                            >
                                <td>
                                    <button
                                        type="button"
                                        aria-label={t(messages.addRowButton.id)}
                                        onClick={() => onAddRow(rowIndex)}
                                        className={classNames(
                                            "easel-table-row-action-button",
                                            "easel-table-row-add-action-button",
                                            styles.actionButton,
                                            styles.actionButtonAdd
                                        )}
                                    >
                                        <PlusIcon />
                                    </button>
                                </td>
                                {row.cells.map((cell, cellIndex) => {
                                    return (
                                        <td
                                            className={classNames("easel-table-container-cell", styles.tableCell)}
                                            key={cellIndex}
                                        >
                                            <div
                                                className={classNames(
                                                    "easel-table-container-cell-content",
                                                    styles.tableCellContent
                                                )}
                                            >
                                                {cell.render("Cell", { updateTableItemData, setFocusRowIndex })}
                                            </div>
                                        </td>
                                    );
                                })}
                                <td>
                                    <button
                                        type="button"
                                        aria-label={t(messages.deleteRowButton.id)}
                                        onClick={() => onDeleteRow(rowIndex)}
                                        className={classNames(
                                            "easel-table-row-action-button",
                                            { "easel-table-row-delete-action-button-hidden": data.length === 1 },
                                            styles.actionButton,
                                            { [styles.actionButtonHidden]: data.length === 1 }
                                        )}
                                    >
                                        <DeleteIcon aria-hidden={true} />
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <button
                type="button"
                className={classNames("easel-table-big-add-row-button", styles.addRowButton)}
                aria-label={t(messages.bigAddRowButton.id)}
                onClick={() => onAddRow(data.length)}
                style={{
                    width: isSmall
                        ? `calc(${columns.length * 50}vw - ${columns.length * 43}px)`
                        : `${columns.length * 170}px`
                }}
            >
                <div className={classNames("easel-table-big-add-row-text", styles.addRowButtonText)}>
                    <PlusIcon />
                    <Typography fontSize="1" fontWeight="normal" ml={2} textColor="black">
                        {t(messages.bigAddRowButtonText.id)}
                    </Typography>
                </div>
            </button>
        </div>
    );
}

TableContainer.displayName = "TableContainer";
