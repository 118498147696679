import React, { useEffect, useState } from "react";
import { Row as ReactTableRow, Column as ReactTableColumn } from "react-table";
import classNames from "classnames";

import * as styles from "./TableContainerCell.module.scss";

interface TableContainerCellProps {
    value: string;
    row: ReactTableRow;
    column: ReactTableColumn;
    updateTableItemData: (rowIndex: number, columnId: string | undefined, cellText: string) => void;
    setFocusRowIndex: (rowIndex: number) => void;
}

export function TableContainerCell({
    value: initialCellText,
    row: { index: rowIndex },
    column: { id: columnId },
    updateTableItemData,
    setFocusRowIndex
}: TableContainerCellProps) {
    const [cellText, setCellText] = useState(initialCellText);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCellText(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateTableItemData(rowIndex, columnId, cellText);
    };

    useEffect(() => {
        setCellText(initialCellText);
    }, [initialCellText]);

    return (
        <input
            onFocus={() => {
                setFocusRowIndex(rowIndex);
            }}
            className={classNames("easel-table-container-cell-input", styles.tableContainerCell)}
            value={cellText}
            onChange={onChange}
            onBlur={onBlur}
            type="text"
        />
    );
}

TableContainerCell.displayName = "TableContainerCell";
