import React from "react";

interface Props {
    props?: React.SVGProps<SVGSVGElement>;
}

export function PlusIcon(props: Props) {
    return (
        <svg viewBox="0 0 20 20" stroke="currentColor" {...props}>
            <line x1="3.75" y1="10" x2="16.25" y2="10" strokeWidth="2" />
            <line x1="10" y1="3.75" x2="10" y2="16.25" strokeWidth="2" />
        </svg>
    );
}
PlusIcon.displayName = "PlusIcon";
